var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"edu"}},[_c('div',{staticClass:"page-title-wrapper",attrs:{"aria-label":"Page title"}},[_c('div',{staticClass:"container"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_vm._m(0),_vm._m(1),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"target":"_self","href":("/educatie/" + (_vm.section.slug) + "/")}},[_vm._v(_vm._s(_vm.section.name.toLowerCase()))])])])]),(_vm.section)?_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.section.name))]):_vm._e(),_c('span',{staticClass:"d-block mt-2 text-muted"}),_c('hr',{staticClass:"mt-4"})])]),_c('div',{staticClass:"container pb-5 mb-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"offcanvas-sidebar"},[_c('div',{staticClass:"offcanvas-sidebar-toggle"},[_c('span',{staticClass:"toggle-knob"},[_c('svg',{staticClass:"feather feather-chevrons-right",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"13 17 18 12 13 7"}}),_c('polyline',{attrs:{"points":"6 17 11 12 6 7"}})]),_vm._v("Categorie & filter ")])]),_c('div',{staticClass:"offcanvas-sidebar-body"},[_c('div',{staticClass:"offcanvas-sidebar-body-inner"},[_c('div',{staticClass:"widget widget-categories mb-4 py-1",attrs:{"ng-show":"ctrl.items.aggs.category_id_category.length > 0"}},[_c('h3',{staticClass:"widget-title"},[_vm._v("Categorie:")]),(_vm.section)?_c('ul',{attrs:{"id":"shopCategories","ng-cloak":""}},[_c('li',{staticStyle:{"padding":"0"}},[_c('router-link',{staticStyle:{"color":"#222222"},attrs:{"to":{
                        name: 'Educatie',
                        params: {
                          section: _vm.section.slug,
                        },
                      }}},[_vm._v(" Alles ")])],1),_vm._l((_vm.categories),function(cat){return _c('li',{key:cat.slug,staticStyle:{"padding":"0"}},[_c('router-link',{staticStyle:{"color":"#222222"},attrs:{"to":{
                        name: 'Educatie',
                        params: {
                          section: _vm.section.slug,
                          cat: cat.slug,
                        },
                      }}},[_vm._v(" "+_vm._s(cat.name)+" ")])],1)})],2):_vm._e()]),_c('div',{staticClass:"card-body pb-1",attrs:{"ng-editable":"edu-remarks"}}),_c('a',{staticClass:"d-block text-decoration-0",attrs:{"href":"/inloopdagen/","ng-show":"ctrl.items.items"}},[_c('div',{staticClass:"bg-secondary"},[_vm._m(2),_c('OpenDoorDayWidget',{attrs:{"size":"small"}})],1)])])])])]),_c('div',{staticClass:"col-lg-9"},[_vm._m(3),(_vm.section)?_c('EditableBlock',{attrs:{"blocks":_vm.blocks,"page_id":_vm.section.page.id,"block_name":"description","type":"internalblocks"}}):_vm._e(),_c('div',{staticClass:"row pb-3"},_vm._l((_vm.courses),function(course){return _c('div',{key:course.id,staticClass:"mb-3 col-sm-6 px-3 animate-repeat"},[_c('div',{staticClass:"card",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title",staticStyle:{"height":"2.4em"}},[_c('a',{attrs:{"href":_vm.$router.resolve({
                            name: 'Course',
                            params: {
                              slug: course.slug,
                            },
                          }).href}},[_vm._v(" "+_vm._s(course.name)+" ")])]),(course.seo_description)?_c('span',{staticClass:"card-text font-size-sm text-muted",domProps:{"innerHTML":_vm._s(course.seo_description)}}):_c('span',{staticClass:"card-text font-size-sm text-muted",domProps:{"innerHTML":_vm._s(course.description)}})])])])])])}),0),(_vm.section)?_c('EditableBlock',{attrs:{"blocks":_vm.blocks,"page_id":_vm.section.page.id,"block_name":"description-bottom","type":"internalblocks"}}):_vm._e()],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"mt-n1 mr-1"},[_c('i',{attrs:{"data-feather":"home"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"target":"_self","href":"/"}},[_vm._v("Home")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3 pt-4 text-center"},[_c('h4',{staticClass:"font-size-sm font-weight-normal pt-1 mb-2"},[_vm._v(" Aankomende ")]),_c('h4',{staticClass:"h5 pb-2"},[_vm._v("Infodag")]),_c('div',{staticClass:"btn btn-primary btn-sm mb-3"},[_vm._v("Meer info")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-description pb-3 d-none",attrs:{"ng-editor-wrapper":"","data-page-id":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"card",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"card-body pb-1",attrs:{"ng-editable":"section-description"}})])])])])}]

export { render, staticRenderFns }