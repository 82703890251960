<template>
  <div id="course">
    <div class="page-title-wrapper" aria-label="Page title">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="mt-n1 mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-home"
              >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
            </li>
            <li class="breadcrumb-item"><a target="_self" href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a target="_self" :href="`/educatie/${section.slug}/`">{{section.name.toLowerCase()}}</a>
            </li>
          </ol>
        </nav>
        <h1 class="page-title">Filmtussenjaar</h1>
        <span class="d-block mt-2 text-muted"></span>
        <hr class="mt-4" />
      </div>
    </div>
    <!-- Page Content-->

    <div class="container pb-5 mb-2">
      <div class="row">
        <div class="col-xl-9 col-lg-8 no-gutters">
          <img
            src="/media/uploads/edu/landingspagina_tussenjaar.jpg"
            class="img-fluid"
            alt="Tussenjaarfilm"
          />
          <div class="mt-5">
            <EditableBlock
              v-if="section"
              :blocks="blocks"
              :page_id="section.page.id"
              block_name="intro"
              type="internalblocks"
            />
            <!--<h2>Wanneer is het Filmtussenjaar echt iets voor mij?</h2>
            <p class="lead">
              Klaar met de middelbare school en droom je van een filmopleiding?
              En zoek je een tussenjaar vol film en creatieve uitdaging? Het
              Filmtussenjaar bereid je optimaal voor op een filmopleiding.
              Ontwikkel je creativiteit, leer filmmaken van professionals en
              werk aan je portfolio. Neem regie over je toekomst, vergroot je
              kans op toelating.
            </p>-->
            <!--<div class="row">
            <div class="col-xl-6 col-lg-6">
              <a
                class="btn btn-outline-primary btn-block rounded mb-2"
                role="button"
                href="/opleidinge/"
                >Filmtussenjaar Ori&euml;ntatie 2 dagen per week &rarr;</a
              >
            </div>
            <div class="col-xl-6 col-lg-6">
              <a
                class="btn btn-outline-success btn-block rounded mb-2"
                role="button"
                href="/opleidinge/"
                >Filmtussenjaar Creatief 3 dagen per week &rarr;</a
              >
            </div>
          </div>
          -->
            <!--end row tussenjaren buttons-->
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  v-for="(quote, index) in section.quotes"
                  :key="index === 0 ? 'active' : ''"
                  data-target="#carouselExampleIndicators"
                  :data-slide-to="index"
                  :class="index === 0 ? 'active' : ''"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div
                  v-for="(quote, index) in section.quotes"
                  class="carousel-item"
                  :key="quote.id"
                  :class="index === 0 ? 'active' : ''"
                >
                  <blockquote class="blockquote mt-5">
                    <p class="mb-0">
                      {{ quote.quote }}
                    </p>
                    <footer class="blockquote-footer">
                      {{ quote.author }}
                      <cite title="Source Title" v-if="quote.author_age"
                        >({{ quote.author_age }})</cite
                      >
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--enc col9 image-->
        <div class="col-xl-3 col-lg-4">
          <!-- Related articles sidebar-->
          <div class="" id="inschrijven">
            <!--side sign up-->

            <!--side nav-->
            <div class="widget widget-links">
              <h3 class="widget-title">Varianten</h3>
              <a
                class="btn btn-outline-primary btn-block rounded mb-2"
                role="button"
                href="/educatie/c/orientatiejaar-filmopleidingen-2-dagen-p-w"
                >Filmtussenjaar Ori&euml;ntatie &rarr;</a
              >
              <a
                class="btn btn-outline-success btn-block rounded mb-2"
                role="button"
                href="/educatie/c/vooropleiding-video-digitale-media-3-dagen-p-w"
                >Filmtussenjaar Creatief &rarr;</a
              >
              <!--<a
                class="btn btn-outline-dark rounded mb-2"
                role="button"
                href="/opleidinge/"
                >&larr; Overzicht alle cursussen</a
              >
              <a
                class="btn btn-outline-danger rounded"
                role="button"
                href="/agenda/"
                >Agenda</a
              >-->
            </div>
            <div>
              <!--end widget inloopdag-->
            </div>
          </div>
          <!--end side nav-->
          <InfodagWidget size="small" />
          <a
            href="https://www.instagram.com/openstudioamsterdam"
            target="_blank"
          >
            <img
              src="/media/uploads/edu/instagram-afbeeling.jpg"
              class="img-fluid"
              alt="Tussenjaarfilm"
            />
          </a>
        </div>
      </div>

      <div class="row mt-4">
        <!-- Card group -->
        <div class="col">
          <div class="card-group mb-4">
            <!-- Card -->
            <!--<div class="card d-none d-lg-block">
              <img
                src="/media/uploads/edu/comparison/compare-tussenjaarfilm-tussenjaar.png"
                class="card-img-top"
                alt="Card image"
              />
              <div class="card-body">
                <h5 class="card-title">Tussenjaren &rarr;</h5>
                <p class="card-text font-size-sm text-muted">
                  <EditableBlock
                    v-if="section"
                    :blocks="blocks"
                    :page_id="section.page.id"
                    block_name="tussenjaren"
                    type="internalblocks"
                  />
                </p>
              </div>
            </div>-->

            <!-- Card -->
            <div class="card">
              <img
                src="/media/uploads/edu/comparison/compare-tussenjaarfilm-orientatiejaar.png"
                class="card-img-top"
                alt="Card image"
              />
              <div class="card-body">
                <a
                  href="/educatie/c/orientatiejaar-filmopleidingen-2-dagen-p-w"
                  class=""
                  ><h5 class="card-title">Filmtussenjaar Ori&euml;ntatie</h5></a
                >
                <p class="card-text font-size-sm text-muted">
                  <EditableBlock
                    v-if="section"
                    :blocks="blocks"
                    :page_id="section.page.id"
                    block_name="orientatie-short-description"
                    type="internalblocks"
                  />
                </p>
                <div class="list-group list-group-flush">
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="calendar"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span></span>2 dagen per week</span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="calendar"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span></span>ma-di of do-vr</span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="percent"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>&euro; 600 huurcoupon</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="percent"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>2 jaar huren met 25% korting</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="shopping-bag"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>totaal &euro; 3495</span></span
                  >

                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>mentor</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>one minute</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>informatie en orientatie</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>portfolio maken</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>toelating voorbereiden</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>eindspurt</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-danger mr-2"
                      data-feather="x"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>extra coaching</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-danger mr-2"
                      data-feather="x"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>experimentele film</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-danger mr-2"
                      data-feather="x"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>videoclip</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-danger mr-2"
                      data-feather="x"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>meercamera project</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-danger mr-2"
                      data-feather="x"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>keuzeproject</span></span
                  >
                </div>
                <!--end list items-->
                <a
                  href="/educatie/c/orientatiejaar-filmopleidingen-2-dagen-p-w"
                  class="btn btn-outline-primary btn-block mt-4"
                  >Naar het programma en startdata</a
                >
              </div>
              <!--end card body-->
            </div>
            <!--end card-->

            <!-- Card -->
            <div class="card">
              <img
                src="/media/uploads/edu/comparison/compare-tussenjaarfilm-creatiefjaar.png"
                class="card-img-top"
                alt="Card image"
              />
              <div class="card-body">
                <a
                  href="/educatie/c/vooropleiding-video-digitale-media-3-dagen-p-w"
                  class=""
                  ><h5 class="card-title">Filmtussenjaar Creatief</h5></a
                >
                <p class="card-text font-size-sm text-muted">
                  <EditableBlock
                    v-if="section"
                    :blocks="blocks"
                    :page_id="section.page.id"
                    block_name="creatief-short-description"
                    type="internalblocks"
                  />
                </p>
                <div class="list-group list-group-flush">
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="calendar"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span></span>3 dagen per week</span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="calendar"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span></span>ma-di-wo of wo-do-vr</span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="percent"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>&euro; 750 huurcoupon</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="percent"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>2 jaar huren met 25% korting</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="shopping-bag"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>totaal &euro; 4750</span></span
                  >

                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>mentor</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>one minute</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>informatie en orientatie</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>portfolio maken</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>toelating voorbereiden</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>eindspurt</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>extra coaching</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>experimentele film</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>videoclip</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>meercamera project</span></span
                  >
                  <span class="list-group-item list-group-item-action"
                    ><i
                      class="text-success mr-2"
                      data-feather="check"
                      style="width: 1rem; height: 1rem"
                    ></i
                    ><span>keuzeproject</span></span
                  >
                </div>
                <a
                  href="/educatie/c/vooropleiding-video-digitale-media-3-dagen-p-w"
                  class="btn btn-outline-primary mt-4 btn-block"
                  >Naar het programma en startdata</a
                >
                <!--end list items-->
              </div>
              <!--end card body-->
            </div>
            <!--end card-->
          </div>
        </div>
        <!--end card group-->
      </div>
      <!--end row compare-->
      <div class="d-block d-md-none accordion" role="tablist">
        <b-card no-body class="mb-1" v-for="faq in section.faqs" :key="faq.id">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <h3 class="accordion-heading">
              <a role="button" v-b-toggle="`accordion-${faq.id}`">
                {{ faq.question }}
                <span class="accordion-indicator"
                  ><i data-feather="chevron-up"></i
                ></span>
              </a>
            </h3>
          </b-card-header>
          <b-collapse
            :id="`accordion-${faq.id}`"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text> </b-card-text>
              {{ faq.answer }}
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div
        v-for="faq in section.faqs"
        :key="faq.id"
        class="d-none d-md-flex row mb-4 mt-4"
      >
        <div class="col-xl-3 col-md-4 border-bottom pb-4">
          <h3>{{ faq.question }}</h3>
        </div>
        <div
          class="col-xl-9 col-md-8 border-bottom pb-4"
          style="white-space: pre-line"
        >
          {{ faq.answer }}
        </div>
      </div>
      <ContactWidget
        :to="'edu@openstudio.nl'"
        :subject="`Aanvraag adviesgesprek ${this.section.name}`"
      />
    </div>
  </div>
</template>

<style scoped>
.os-header-img-container {
  min-height: 330px;
  max-height: 400px;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  background-size: 100% auto;
}
.carousel-indicators li {
  background-color: #aaa;
}
</style>

<script>
import axios from "axios";
import ContactWidget from "@/components/ContactWidget.vue";
import InfodagWidget from "@/components/InfodagWidget.vue";
import EditableBlock from "@/components/EditableBlock.vue";

export default {
  name: "CourseView2EditableBlock",
  components: {
    // EditableBlock,
    EditableBlock,
    ContactWidget,
    InfodagWidget,
  },
  filters: {
    toFloat: function (value) {
      return parseFloat(value);
    },
  },
  data: function () {
    return {
      section: {},
      blocks: new Map(),
    };
  },
  computed: {},
  methods: {
    fetchData() {
      axios.get("/api/sections/tussenjaar/").then((response) => {
        this.section = response.data;
        axios
          .get("/api/main/internalpages/" + this.section.page.id + "/")
          .then((response) => {
            this.blocks = new Map(
              response.data.blocks.map(function (b) {
                return [b.name, b];
              })
            );
          });
      });
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      { immediate: true }
    );
  },
};
</script>
