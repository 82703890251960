<template>
  <div id="edu">
    <div class="page-title-wrapper" aria-label="Page title">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="mt-n1 mr-1"><i data-feather="home"></i></li>
            <li class="breadcrumb-item">
              <a target="_self" href="/">Home</a>
            </li>
            <!--<li class="breadcrumb-item">
              <a target="_self" href="/educatie/">educatie</a>
            </li>-->
            <li class="breadcrumb-item">
              <a target="_self" :href="`/educatie/${section.slug}/`">{{section.name.toLowerCase()}}</a>
            </li>
          </ol>
        </nav>
        <h1 v-if="section" class="page-title">{{ section.name }}</h1>
        <span class="d-block mt-2 text-muted"></span>
        <hr class="mt-4" />
      </div>
    </div>
    <div class="container pb-5 mb-4">
      <div class="row">
        <div class="col-lg-3">
          <!-- Shop sidebar-->
          <div class="offcanvas-sidebar">
            <div class="offcanvas-sidebar-toggle">
              <span class="toggle-knob">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7"></polyline>
                  <polyline points="6 17 11 12 6 7"></polyline></svg
                >Categorie &amp; filter
              </span>
            </div>
            <div class="offcanvas-sidebar-body">
              <div class="offcanvas-sidebar-body-inner">
                <!-- Categories-->
                <!--<div class="widget widget-categories mb-4 py-1">
                <h3 class="widget-title">Filter artikelen</h3>
                <div class="flex-grow-1">
                      <div class="input-group flex-nowrap">
                    <div class="input-group-prepend">
                       <span class="input-group-text rounded-left" id="search-icon"><i data-feather="search"></i></span>
                    </div>
                      <input ng-model="articleCtrl.filterText"
                           ng-change="articleCtrl.filterItems()"
                           class="form-control rounded-right" 
                           type="text"
                           id="site-search"
                           placeholder="Filter"
                           aria-label="Filter" 
                           aria-describedby="search-icon">
                      </div>
                </div>
                </div>-->
                <div
                  class="widget widget-categories mb-4 py-1"
                  ng-show="ctrl.items.aggs.category_id_category.length > 0"
                >
                  <h3 class="widget-title">Categorie:</h3>
                  <ul v-if="section" id="shopCategories" ng-cloak>
                    <li style="padding: 0">
                      <router-link
                        :to="{
                          name: 'Educatie',
                          params: {
                            section: section.slug,
                          },
                        }"
                        style="color: #222222"
                      >
                        Alles
                      </router-link>
                    </li>
                    <li
                      v-for="cat in categories"
                      style="padding: 0"
                      :key="cat.slug"
                    >
                      <router-link
                        :to="{
                          name: 'Educatie',
                          params: {
                            section: section.slug,
                            cat: cat.slug,
                          },
                        }"
                        style="color: #222222"
                      >
                        {{ cat.name }}
                      </router-link>
                    </li>
                  </ul>
                </div>
                <!--Remarks-->
                <div class="card-body pb-1" ng-editable="edu-remarks">
                  <!--{{ blocks.get('edu-remarks', '') }}-->
                </div>
                <!-- END Remarks-->
                <!-- Promo banner-->
                <a
                  class="d-block text-decoration-0"
                  href="/inloopdagen/"
                  ng-show="ctrl.items.items"
                >
                  <div class="bg-secondary">
                    <div class="px-3 pt-4 text-center">
                      <h4 class="font-size-sm font-weight-normal pt-1 mb-2">
                        Aankomende
                      </h4>
                      <h4 class="h5 pb-2">Infodag</h4>
                      <div class="btn btn-primary btn-sm mb-3">Meer info</div>
                    </div>
                    <OpenDoorDayWidget size="small" /></div
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div
            ng-editor-wrapper
            data-page-id=""
            class="section-description pb-3 d-none"
          >
            <div class="row">
              <div class="col-sm-12">
                <div class="card" style="width: 100%; height: 100%">
                  <div class="card-body pb-1" ng-editable="section-description">
                    <!--{{ blocks.get('section-description', '') }}-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- search-box-->
          <!--
          <div class="row">
              <div class="flex-grow-1 pb-1 my-1 px-sm-2 pr-lg-4 order-sm-2">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend"><span class="input-group-text rounded-left" id="search-icon"><i data-feather="search"></i></span>
                      </div>
                          <input ng-model="articleCtrl.filterText" ng-change="articleCtrl.filterItems()" class="form-control rounded-right" type="text" id="site-search" placeholder="Zoeken binnen categorie" aria-label="Zoeken binnen categorie" aria-describedby="search-icon">
                    </div>
              </div>
            </div>
          -->
          <!-- Horizontal card layout -->
          <EditableBlock
            v-if="section"
            :blocks="blocks"
            :page_id="section.page.id"
            block_name="description"
            type="internalblocks"
          />
          <div class="row pb-3">
            <div
              v-for="course in courses"
              :key="course.id"
              class="mb-3 col-sm-6 px-3 animate-repeat"
            >
              <div class="card" style="width: 100%; height: 100%">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card-body">
                      <h5 class="card-title" style="height: 2.4em">
                        <a
                          :href="
                            $router.resolve({
                              name: 'Course',
                              params: {
                                slug: course.slug,
                              },
                            }).href
                          "
                        >
                          {{ course.name }}
                        </a>
                        <!--<router-link
                          :to="{
                            name: 'Course',
                            params: {
                              slug: course.slug,
                            },
                          }"
                          style="color: #222222"
                        >
                          {{ course.name }}
                        </router-link>-->
                      </h5>
                      <span
                        v-if="course.seo_description"
                        v-html="course.seo_description"
                        class="card-text font-size-sm text-muted"
                      ></span>
                      <span
                        v-else
                        v-html="course.description"
                        class="card-text font-size-sm text-muted"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EditableBlock
            v-if="section"
            :blocks="blocks"
            :page_id="section.page.id"
            block_name="description-bottom"
            type="internalblocks"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OpenDoorDayWidget from "@/components/OpenDoorDayWidget.vue";
import EditableBlock from "@/components/EditableBlock.vue";

export default {
  name: "EduView",
  components: {
    OpenDoorDayWidget,
    EditableBlock,
  },
  data: function () {
    return {
      courses: [],
      categories: [],
      section: null,
      blocks: new Map(),
    };
  },
  methods: {
    fetchData() {
      var params = {
        params: {
          sections__slug: this.$route.params.section,
          no_page: true,
        },
      };
      if (this.$route.params.cat) {
        params = {
          params: {
            sections__slug: this.$route.params.section,
            categories__slug: this.$route.params.cat,
            no_page: true,
          },
        };
      }
      axios
        .get("/api/courses/", params)
        .then((response) => (this.courses = response.data));
      axios
        .get("/api/sections/" + this.$route.params.section + "/")
        .then((response) => {
          this.section = response.data;
          axios
            .get("/api/main/internalpages/" + this.section.page.id + "/")
            .then((response) => {
              this.blocks = new Map(
                response.data.blocks.map(function (b) {
                  return [b.name, b];
                })
              );
            });
        });
      axios
        .get("/api/categories/", {
          params: {
            courses__sections__slug: this.$route.params.section,
            no_page: true,
          },
        })
        .then((response) => (this.categories = response.data));
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      { immediate: true }
    );
  },
};
</script>
